import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { LoadService } from "../services/load.service";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private _authenticateService: AuthenticationService,
    private _loadService: LoadService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token: any = localStorage.getItem("USER");
    if (token) {
      try {
        const decoded: any = jwtDecode(token);
        if (decoded && !this._authenticateService.isTokenExpired(decoded)) {
          this._loadService.loadPersonalData(decoded.id);
          this._loadService.loadAppData();
          return true;
        } else {
          this.redirectToSignIn();
          return false;
        }
      } catch (e) {
        localStorage.removeItem("USER");
        this.redirectToSignIn();
        return false;
      }
    } else {
      this.redirectToSignIn();
      return false;
    }
  }

  private redirectToSignIn() {
    this.router.navigate(["sign/sign-in"]);
  }
}
