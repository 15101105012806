import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/user/` + idUser
    ) as Observable<any>;
  }
  createUser(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user`,
      user
    ) as Observable<any>;
  }
  updateUser(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/update`,
      user
    ) as Observable<any>;
  }
  updateUserPass(user: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/user/password/update`,
      user
    ) as Observable<any>;
  }
}
