import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import jwtDecode from "jwt-decode";
import { environment } from "../../environments/environment";
import { AdsService } from "./ads.service";
import { ForfaitService } from "./forfait.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private _user: BehaviorSubject<any> = new BehaviorSubject(null);
  public user$: Observable<any> = this._user.asObservable();

  private _ads: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public ads$: Observable<any[]> = this._ads.asObservable();

  private _forfaits: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public forfaits$: Observable<any[]> = this._forfaits.asObservable();

  public loaded: boolean = false;

  constructor(
    private http: HttpClient,
    private _userService: UserService,
    private _adService: AdsService,
    private _forfaitService: ForfaitService
  ) {}

  getUser() {
    return this._user.getValue();
  }

  getAds() {
    return this._ads.getValue();
  }

  updateUser(idUser: number | null) {
    if (!idUser) {
      this._user.next(null);
    } else {
      this._userService.getUser(idUser).subscribe((data) => {
        this._user.next(data);
      });
    }
  }

  isLoggedIn(): boolean {
    const token: string | null = localStorage.getItem("USER");
    if (!!token) var decoded: any = jwtDecode(token!);
    if (token && !this.isTokenExpired(decoded)) {
      return true;
    }
    return false;
  }

  login(mail: string, password: string): Observable<any> {
    return this.http.post(environment.apiURL + `/auth/login`, {
      mail,
      password,
    });
  }

  resetPassword(mail: string): Observable<any> {
    return this.http.post(environment.apiURL + `/auth/resetPassword`, {
      mail,
    });
  }

  /*   async load(idUser: number) {
    await this._userService
      .getUser(idUser)
      .toPromise()
      .then((user) => {
        this._user.next(user);
      })
      .catch((err) => console.log(err));

    await this._adService
      .getAdsByUser(idUser)
      .toPromise()
      .then((ads) => {
        if (ads) this._ads.next(ads);
      })
      .catch((err) => console.log(err));

    await this._forfaitService
      .getForfaits()
      .toPromise()
      .then((forfaits) => {
        if (forfaits) this._forfaits.next(forfaits);
      });
    this.loaded = true;
  } */

  getTokenExpirationDate(decoded: any): Date | null {
    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(decoded: string): boolean {
    if (!decoded) {
      return true;
    }
    const date = this.getTokenExpirationDate(decoded);
    if (date === undefined || !date) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }
}
