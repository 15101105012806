import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(private http: HttpClient) { }

  getAdsByUser(idUser: any): Observable<any[]> {
    return this.http.get(
      environment.apiURL + `/publication/user/` + idUser
    ) as Observable<any[]>;
  }

  createAd(ad: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/publication`,
      ad
    ) as Observable<any>;
  }
  renewAd(ad: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/publication/renew`,
      ad
    ) as Observable<any>;
  }
  saveNumBill(saveNumBill: any): Observable<any[]> {
    return this.http.post(
      environment.apiURL + `/publication/bill/reserveNum`,
      saveNumBill
    ) as Observable<any>;
  }

  saveContrat(base64: any, id_ad_settings: any) {
    return this.http.post(environment.apiURL + `/publication/save-contrat/`, {
      contrat: base64,
      id_ad_settings,
    }) as Observable<any>;
  }

  updateAd(ad: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/publication/update`,
      ad
    ) as Observable<any>;
  }

  sendAdvertising(property: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/advertising/dashboard`,
      property
    ) as Observable<any>;
  }

  sendValuation(property: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/property-valuation/dashboard`,
      property
    ) as Observable<any>;
  }

  getPhotoByAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/photos/` + idAd
    ) as Observable<any>;
  }

  getVideosByAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/videos/` + idAd
    ) as Observable<any>;
  }

  getBillByAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/bill/` + idAd
    ) as Observable<any>;
  }
}
