import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForfaitService {

  constructor(private http: HttpClient) { }

  getSources() {
    return this.http.post(
      environment.apiURL + `/forfait/sources`,
      {}
    ) as Observable<any[]>;
  }

  getForfaits() {
    return this.http.post(environment.apiURL + `/forfait`, {}) as Observable<any[]>;
  }
}
