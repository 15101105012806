import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, firstValueFrom } from "rxjs";
import { ForfaitService } from "./forfait.service";
import { RpService } from "./rp.service";
import { OptionsService } from "./options.service";
import { UserService } from "./user.service";
import { AdsService } from "./ads.service";

@Injectable({
  providedIn: "root",
})
export class LoadService {
  private _forfaits: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private _optionPrices: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  private _RP_prices: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private _sources: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private _user: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _ads: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public optionPrices$: Observable<any[]> = this._optionPrices.asObservable();
  public RP_prices$: Observable<any[]> = this._RP_prices.asObservable();
  public forfaits$: Observable<any[]> = this._forfaits.asObservable();
  public source$: Observable<any[]> = this._sources.asObservable();
  public user$: Observable<any> = this._user.asObservable();
  public ads$: Observable<any[]> = this._ads.asObservable();

  constructor(
    private _forfaitService: ForfaitService,
    private _rpService: RpService,
    private _optionService: OptionsService,
    private _userService: UserService,
    private _adService: AdsService
  ) {}

  getForfaits() {
    return this._forfaits.getValue();
  }

  getOptionPrices() {
    return this._optionPrices.getValue();
  }

  getRP_prices() {
    return this._RP_prices.getValue();
  }

  getSources() {
    return this._sources.getValue();
  }

  getUser() {
    return this._user.getValue();
  }

  getAds() {
    return this._ads.getValue();
  }

  async loadPersonalData(idUser: number) {
    try {
      const user = await firstValueFrom(this._userService.getUser(idUser));
      console.log("user", { user });
      this._user.next(user);
    } catch (err) {
      console.log(err);
    }

    try {
      const ads = await firstValueFrom(this._adService.getAdsByUser(idUser));
      console.log("ads", { ads });
      this._ads.next(ads);
    } catch (err) {
      console.log(err);
    }
  }

  async loadAppData() {
    try {
      const forfait = await firstValueFrom(this._forfaitService.getForfaits());
      console.log("forfait", { forfait });
      this._forfaits.next(forfait);
    } catch (err) {
      console.log(err);
    }

    try {
      const optionPrices = await firstValueFrom(
        this._optionService.getOptionPrices()
      );
      console.log("optionPrices", { optionPrices });
      this._optionPrices.next(optionPrices);
    } catch (err) {
      console.log(err);
    }

    try {
      const rpPrices = await firstValueFrom(this._rpService.getRP_prices());
      console.log("rpPrices", { rpPrices });
      this._RP_prices.next(rpPrices);
    } catch (err) {
      console.log(err);
    }

    try {
      const source = await firstValueFrom(this._forfaitService.getSources());
      console.log("source", { source });
      this._sources.next(source);
    } catch (err) {
      console.log(err);
    }
  }
}
